<template>
    <div>
        <loading :active.sync="loading" color="rgb(36, 71, 136)" transition="fade"/>

        <div v-if="!loading" class="report">
            <header style="background-color: rgb(36, 71, 136);">
                <!-- /content-left -->

                <div class="container">
                    <div class="mt-3 mb-3 row">
                        <div class="col-md-3">
                            <a href="#" id="logo-reference">
                                <img class="mb-2 mb-md-0" src="../assets/logo_negativo.png" alt="RetImpresa Reference">
                            </a>
                        </div>
                        <div class="col-md-6 align-content-center">
                            <h2 class="text-center text-white">{{ data.extra_fields.denominazione }} </h2>
                        </div>
                        <div class="col-md-3 d-flex">
                            <SocialIcons></SocialIcons>
                            <PrintButton/>
                        </div>
                    </div>
                </div>
                <!-- /container -->
            </header>
            <div class="container">
    <!--            Sidebar Laterale Introduzione -->
                <div class="row">
                    <div class="col" id="start">
    <!--                    Introduzione -->
                        <div class="descrizione-rete">
                            <div class="table-details">
                                <div class="mt-3 row">
                                    <div class="col-lg-12">
                                        <span class="small">Riepilogo Dati Rete</span>
                                        <h2 class="mb-2 font-weight-bold">Descrizione Rete</h2>
                                    </div>
                                    <div class="col-lg-12 d-md-flex">
                                        <div>
                                            <img v-if="data.extra_fields.logoPath" :src="getLogoUrl(data.extra_fields.logoPath)" class="mt-1 mb-3 d-lg-none logo-rete" crossorigin="*">
                                            <table class="table table-borderless">
                                                <tbody>
                                                <tr>
                                                    <th scope="row" class="title-row">Tipologia Rete</th>
                                                    <td>{{data.extra_fields.tiplogiaRete }}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">Natura giuridica Rete</th>
                                                    <td>{{data.extra_fields.naturaGiuridicaRete ? data.extra_fields.naturaGiuridicaRete : 'N/A'}}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">Durata del Contratto di Rete</th>
                                                    <td>{{data.extra_fields.durataContratto }} anni</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">{{ data.extra_fields.impreseAderenti.length + 1}} Imprese aderenti alla Rete</th>
                                                    <td>
                                                        <ul>
                                                            <li>
                                                                <strong>{{data.extra_fields.referente.denominazione}} (Referente)</strong> -
                                                                {{ data.extra_fields.referente.settore }}
                                                            </li>
                                                            <li v-for="(impresa, idx) in data.extra_fields.impreseAderenti" v-bind:key="idx" class="mt-2">
                                                                <strong>{{impresa.denominazione}}</strong> - {{ impresa.settore }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">La rete formalizza precedenti rapporti di collaborazione tra i partecipanti?</th>
                                                    <td>{{ data.extra_fields.collaborazioni }}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">Anno di avvio dell’attività di Rete</th>
                                                    <td>{{ data.extra_fields.annoInzioRete }}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="title-row">Adesione a RetImpresa</th>
                                                    <td>{{ data.extra_fields.adesioneRetImpresa }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="ml-auto">
                                            <img v-if="data.extra_fields.logoPath" :src="getLogoUrl(data.extra_fields.logoPath)" class="d-none d-lg-block logo-rete" crossorigin="*">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="analisi-generica">
                            <div class="mt-3 row">
                                <div class="col-lg-4">
                                    <div class="mt-4">
                                        <span class="small">Data rilascio: {{ (new Date(data.created_at).toLocaleDateString() )}}</span>
                                        <h2 class="mb-2 font-weight-bold">Il tuo Benchmark Qualitativo</h2>
                                        <p class="mb-4 font-italic text-muted small">Ecco il tuo benchmark, è il frutto dell'analisi delle tue risposte
                                            in base al modello elaborato da RetImpresa per valutare la qualità della tua Rete.
                                        </p>
                                        <div class="mt-2 row justify-content-center align-items-center">
                                            <div class="col-sm-5">
                                                <Score :value="this.data.benchmarks.goals[0].grade"/>
                                            </div>
                                            <div class="text-center col-sm-7 text-sm-left">
                                                <h6 class="mt-3 mt-sm-0">Obiettivo</h6>
                                                <h4>{{ getPrimaryGoal().title }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 apexchart-container">
                                    <apexchart type="radar" :options="options" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>

    <!--                    Moduli -->
                        <div class="mt-5 modules-details">
                            <!-- First Row [Statistics]-->
                            <h2 class="mb-2 font-weight-bold">Come va la tua Rete nei diversi ambiti analizzati? </h2>
                            <p class="mb-4 font-italic text-muted">Conosci quali sono i settori che presentano dei margini di miglioramento e quelli più performanti per avere una visione chiara dell'andamento della tua Rete d'impresa.
                            Scopri come migliorare la tua rete e trovare nuove opportunità sul sito di <a href="www.retimpresa.it" target="_blank"> RetImpresa </a>
                            </p>

                            <div class="row">
                                <div class="mb-4 col-lg-4 col-md-6" v-for="(module, idx) in data.benchmarks.modules" v-bind:key="idx">
                                    <!-- Card-->
                                    <div class="border-0 rounded shadow-sm card">
                                        <div class="p-5 card-body">
                                            <div class="text-center">
                                            <img class="module-image" :src="`${module.module.picture_url}`" crossorigin="*"/>
                                            </div>
                                            <h5 class="module-title">{{ module.module.title}}</h5>
                                            <p class="module-body small text-muted font-italic" v-html="module.module.description"></p>
                                            <div class="progress rounded-pill">
                                                <!-- todo: aggiungere max value del modulo-->
                                                <div role="progressbar"
                                                    :aria-valuenow="module.score"
                                                    aria-valuemin="0"
                                                    aria-valuemax="module.score"
                                                    :style="`width: ${getPercent(module.score, module.module.max_value)}%;`"
                                                    class="progress-bar rounded-pill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

    <!--                    Altri Obiettivi -->
                        <div class="mt-4 selezione-obbiettivo">
                            <!-- First Row [Statistics]-->
                            <h2 class="mb-2 font-weight-bold">Visualizza il benchmark per gli obiettivi secondari</h2>
                            <p class="mb-4 font-italic text-muted">Scopri come si comporta la tua rete secondo gli obiettivi secondari
                                scelti durante il questionario. Questo indice ti dà modo di capire se il tuo obiettivo è correttamente focalizzato.
                            </p>
                            <div class="mt-3">
                                <div class="row" :id="`goal-${idx}`" v-for="(goal, idx) in getOtherGoals()" v-bind:key="idx">
                                    <div class="col-lg-4">
                                        <div class="row justify-content-center align-items-center center-graph">
                                            <div class="col-6 col-sm-5">
                                                <Score :value="getGoalGrade(goal.id)"/>
                                            </div>
                                            <div class="text-center col-12 text-sm-left col-sm-7">
                                                <h6 class="mt-4 mt-md-0">Obiettivo</h6>
                                                <h4>{{goal.title}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 apexchart-container">
                                        <apexchart v-if="idx === 0" type="radar" :options="options" :series="seriesB"></apexchart>
                                        <apexchart v-else type="radar" :options="options" :series="seriesC"></apexchart>
                                    </div>
                                </div>
                            </div>
                        </div>

    <!--                    Domande e Risposte -->
                        <div class="pb-4 domande-risposte">
                            <div class="text-center">
                                <button type="button" class="text-center forward" @click.prevent="showAnswers = !showAnswers">
                                    <span v-if="showAnswers">Nascondi le tue risposte</span>
                                    <span v-if="!showAnswers">Visualizza le tue risposte</span>
                                </button>
                            </div>

                            <div class="my-5 answers-container" v-show="showAnswers">
                                <h2 class="mb-2 font-weight-bold">Le tue risposte al questionario</h2>

                                <div class="answers">
                                    <dl class="mt-4" v-for="(item, idx) in data.submission" :key="idx">
                                        <div v-if="item.question && item.answer">
                                            <dt v-html="item.question.text"></dt>
                                            <dd v-html="item.answer.text"></dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 legend">
                            <h2 class="mb-2 font-weight-bold">Scopri la Scoring Scale</h2>
                            <p class="mb-4 font-italic text-muted">
                                La scoring scale di Reference assegna alla tua rete un benchmark qualitativo espresso da un valore minimo D ad un valore massimo S.
                            </p>
                            <div class="pb-3 mt-3 row">
                                <img style="max-width: 70%; height: 120px; object-fit: contain;" class="mx-auto img-fluid" src="../assets/scoring_scale.png">
                            </div>
                        </div>

                        <div class="pb-3 mt-5 embed">
                            <h2 class="mb-2 font-weight-bold">Condividi il tuo benchmark</h2>
                            <p class="mb-4 font-italic text-muted">
                              Incorpora nel tuo sito il risultato ottenuto su Reference. Utilizza il codice di incorporamento
                              presente qui sotto. Nessuna informazione riservata sarà esposta.
                            </p>
                            <div class="mt-3 mb-5 d-flex align-items-center">
                                <pre style="white-space: normal;" class="mb-0 mr-2 flex-fill" v-text="this.embedCode"></pre>

                                <button type="button" class="btn btn-link text-decoration-none" @click="copyEmbedCode">
                                    Copia codice
                                </button>
                            </div>
                        </div>

                        <!-- /Wizard container -->
                    </div>
                    <!-- /content-right-->
                </div>
                <!-- /row-->
            </div>
            <!-- /container-fluid -->

            <BannerCta/>
        </div>
    </div>
</template>
<script>
  import { Component, Vue } from 'vue-property-decorator';

  import BannerCta from "@/components/BannerCta.vue";
  import PrintButton from "@/components/PrintButton.vue";
  import Score from "@/components/Score.vue";
  import SideDescriptionArea from "@/components/SideDescriptionArea.vue";
  import SocialIcons from "@/components/SocialIcons.vue";
  import Wizard from "@/components/Wizard.vue";

  import * as axios from 'axios';
  import Loading from 'vue-loading-overlay';

  import 'vue-loading-overlay/dist/vue-loading.css';

  @Component({
    components: {
      BannerCta,
      Loading,
      PrintButton,
      Score,
      SideDescriptionArea,
      SocialIcons,
      Wizard
    },
  })

  export default class Report extends Vue {

    /**
     * Loading data
     * @type {boolean}
     */
    loading = true;

    /**
     * Token API Authentication
     * @type {string}
     */
    token = '';

    /**
     * Whether to show the user's answers
     * @type {boolean}
     */
    showAnswers = false;

    /**
     * Submission Id
     * @type {int|string}
     */
    submissionId = 0;

    /**
     * Report Data
     * @type {object}
     */
    data = {
        score: 's',
        'extra_fields': {
            impreseAderenti: []
        },
        submission: [{
          question: {},
          answer: {}
        }]
    };

    options = {
      chart: {
        toolbar: {
          show: false
        },
      },
      legend: {
        show: false
      },
      fill: {
        opacity: 0.4,
        colors: [
          '#00598e',
          '#8e5500',
          '#8e0e00'
        ],
      },
      labels: [],
    responsive: [
        {
            breakpoint: 768,
            options: {
                chart: {
                    width: '600px',
                }
            },
        },
        {
            breakpoint: 9999999,
            options: {
                chart: {
                    width: '800px',
                }
            },
        }
    ]
    }

    series = [
      {
        name: "",
        data: []
      },
    ];

    seriesB = [
      {
        name: "",
        data: []
      }
    ];

    seriesC = [
      {
        name: "",
        data: []
      }
    ]

    async mounted() {
      this.token = this.$route.query.token;
      this.submissionId = this.$route.query.submissionId;
      this.data = await this.getReportData();
      await this.filterModules();
      this.setRadarData()
      this.loading = false;
    }

    get embedCode() {
        return `<iframe src="${window.location.origin}${this.$router.resolve({ name: 'embed-benchmark', params: { 'submission_id': this.data.id }}).href}" width="250" height="400" frameBorder="0"></iframe>`;
    }

    async filterModules() {
      this.data.benchmarks.modules = this.data.benchmarks.modules.filter(module =>
        (module.module.id !== 1) &&
        (module.module.id !== 2) &&
        (module.module.id != 4)
      )
    }

    setRadarData() {
      this.data.benchmarks.goals[0].modules.map(module => {
        this.series[0].data.push(module.score)
        this.options.labels.push(module.title)
      })

      this.data.benchmarks.goals[1].modules.map(module => {
        this.seriesB[0].data.push(module.score)
      })

      this.data.benchmarks.goals[2].modules.map(module => {
        this.seriesC[0].data.push(module.score)
      })
    }

    getPercent(value, max = 10) {
      return Math.round( ( value * 100 ) / max );
    }

    getGoalGrade( goalId = 0 ) {
      return this.data.benchmarks.goals.filter(goal => goal.id === goalId)[0].grade;
    }

    /**
     * Return Primary Goal
     */
    getPrimaryGoal() {
      return this.data.goals.filter(goal => goal.primary )[0]
    }

    /**
     * Return Array of secondary Goals
     */
    getOtherGoals() {
      return this.data.goals.filter(goal => !goal.primary);
    }

    /**
     * Retrieve Report Data from Backend
     * @return {Promise<void>}
     */
    async getReportData() {
        try {
          const response = await axios
            .get(`${process.env.VUE_APP_SURVEY_REST}/api/v1/submissions/${this.submissionId}?token=${this.token}`);

          return response.data.data;
        } catch (e) {
          console.log(`Errore durante la richiesta dei dati per la submission ${this.submissionId}`)
        }
    }

    /**
     * Get the storage path for the logo.
     * @return string
     */
    getLogoUrl(path) {
        return `${process.env.VUE_APP_SURVEY_REST}/storage/${path.replace('public/', '')}`;
    }

    async copyEmbedCode(event) {
        await navigator.clipboard.writeText(this.embedCode);
        event.target.innerText = 'Copiato!';
    }
  }
</script>

<style scoped>
    #logo-reference img {
        display: block;
        margin: 0 auto;
        width: 150px;
    }

    @media (min-width: 768px) {
        #logo-reference img {
            width: 175px;
        }
    }

    #social {
        position: initial;
        display: flex;
        justify-content: center;
    }

    @media (min-width: 768px) {
        #social {
            justify-content: flex-end;
        }
    }

    .logo-rete {
        width: 150px;
    }

    .module-image {
        filter: invert(80%);
        max-width: 100%;
        height: 150px;
        object-fit: contain;
    }

    .module-title {
        margin: 30px 0 15px;
        height: 3rem;
        line-height: 1.4rem;
    }

    .module-body {
        height: 5rem;
    }

    .img-selector {
        max-height: 120px;
    }

    header #social ul li a {
        color: white !important;
    }

    .title-row {
        background-color: rgb(246, 146, 30);
        color: white;
        text-align: center;
        vertical-align: middle;
        width: 55%;
    }

    @media (min-width: 768px)  {
        .title-row {
            width: 265px;
        }
    }

    td {
        vertical-align: middle;
        text-align: left;
    }

    .apexchart-container {
        overflow: hidden;
    }
    .apexchart-container > div {
        margin-left: -125px;
        overflow: hidden;
    }
    @media (min-width: 576px) {
        .apexchart-container {
            margin-left: -75px;
        }
    }

    @media (min-width: 768px) {
        .apexchart-container {
            overflow: inherit;
        }

        .apexchart-container > div {
            margin-left: 0;
            overflow: inherit;
        }

        .center-graph {
            margin-top: 90px;
        }
    }

    .answers {
        border-left: 2px solid #eee;
        padding-left: 1rem;
    }
</style>
