<template>
    <div>
        <button class="print-button btn btn-link text-white" title="Stampa il Report" @click="generatePDF">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="currentColor" d="M7 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7zM5 8c-1.65 0-3 1.35-3 3v4a2 2 0 0 0 2 2h2v2a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-2h2a2 2 0 0 0 2-2v-4c0-1.65-1.35-3-3-3H5zm14 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM8.5 14h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z"/>
            </svg>
        </button>

        <loading :active.sync="isPrinting" color="rgb(36, 71, 136)" transition="fade"/>
    </div>
</template>

<script>
    import html2pdf from 'html2pdf.js';
    import Loading from 'vue-loading-overlay';

    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        data() {
            return {
                isPrinting: false
            };
        },

        components: {
            Loading,
        },

        methods: {
            beforePrinting() {
                document.querySelector('.report').classList.add('is-printing');
                this.isPrinting = true;
            },

            afterPrinting() {
                document.querySelector('.report').classList.remove('is-printing');
                this.isPrinting = false;
            },

            generatePDF() {
                const element = document.querySelector('.report');

                this.beforePrinting();

                html2pdf()
                    .from(element)
                    .set({
                        filename: 'report.pdf',
                        html2canvas: { useCORS: true },
                        jsPDF: { format: 'a4', orientation: 'landscape' },
                        pagebreak: {
                            after: ['.descrizione-rete', '.analisi-generica', '.modules-details', '#goal-0', '#goal-1', '.domande-risposte'],
                            avoid: 'dl'
                        },
                    })
                    .save()
                    .then(() => this.afterPrinting());
            }
        }
    };
</script>

<style>
    /* Print button... */

    .report.is-printing .print-button {
        display: none;
    }

    /* Page breaks... */

    .report.is-printing .analisi-generica,
    .report.is-printing .selezione-obbiettivo,
    .report.is-printing .modules-details,
    .report.is-printing .legend {
        margin-top: 1rem !important;
    }

    .report.is-printing .embed {
        display: none
    }

    /* CTA Banner... */

    .report.is-printing #banner-cta {
        display: none !important;
    }

    /* Modules... */

    .report.is-printing .card-body {
        padding: 1rem !important;
        background-color: #244788;
    }

    .report.is-printing .module-image {
        height: 75px;
        filter: none;
    }

    .report.is-printing .module-title,
    .report.is-printing .module-body {
        color: #fff !important;
    }

    .report.is-printing .module-body {
        height: 4rem;
    }

    /* Goals... */

    .report.is-printing #goal-1 {
        margin-top: 2rem;
    }

    /* Graphs... */

    .report.is-printing .center-graph {
        margin-top: 0;
    }

    /* Questions & Answers... */

    .report.is-printing .domande-risposte button {
        display: none;
    }

    .report.is-printing .domande-risposte .answers-container {
        display: block !important;
        margin: 1rem 0 0;
    }
</style>
