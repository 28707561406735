<template>
    <section id="banner-cta" v-if="isShown">
        <div class="container">
            <div class="row">
                <div class="d-md-flex flex-grow-1 align-items-center mb-3 mb-md-0">
                    <div class="mb-3 mb-md-0 banner-logo">
                        <img src="../assets/registry.png" alt="RetImpresa Registry">
                    </div>

                    <div class="ml-md-5 banner-body">
                        <h4 class="mt-0 mb-1 banner-title">Hai un’idea di rete o vuoi farne parte?</h4>
                        <div class="banner-subtitle">Scopri l’unica piattaforma di Open Innovation per condividere idee di rete tra imprese.</div>
                    </div>
                </div>

                <div class="d-md-flex justify-content-end">
                    <a href="https://registry.retimpresa.it" target="_blank" class="btn btn-primary border-0 banner-button">Inizia da qui</a>
                </div>
            </div>
        </div>

        <button type="button" class="close" aria-label="Chiudi" @click="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                isShown: true
            };
        },

        methods: {
            close() {
                this.isShown = false;
            }
        }
    };
</script>

<style>
#banner-cta {
    position: sticky;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem;
    z-index: 1000;
    color: #244788;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

#banner-cta .banner-logo img {
    height: 2rem;
}

#banner-cta .banner-title {
    font-size: 1.2rem;
    color: #244788;
    line-height: 1;
}

#banner-cta .close {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
    padding: 0.5rem;
    color: #6c757d;
    text-shadow: none;
}

@media (min-width: 992px) {
    #banner-cta .banner-logo img {
        height: 2.5rem;
    }

    #banner-cta .banner-button {
        padding: 0.75rem 1.75rem;
    }

    #banner-cta .close {
        top: 0;
        bottom: 0;
        right: 2rem;
    }
}
</style>
